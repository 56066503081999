import React from "react"

import Layout from "../components/layout"
import Menu from "../components/menu"
import Footer from "../components/footer"
import ProfileContent from "../components/profile-content"

import Login from "./login"
import { withAuthenticator } from "aws-amplify-react"

const ProfilePage = () => (
  <Layout type={2}>
    <Menu type={2} btype={2} />
    <ProfileContent />
    <Footer type={3} />
  </Layout>
)

export default withAuthenticator(ProfilePage, false, [<Login />])
