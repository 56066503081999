import React, { useState } from "react"
import styles from "./profile-content.module.css"
import { Link } from "gatsby"
import { Alert } from "@material-ui/lab"
import { API, Auth, Storage } from "aws-amplify"
import { listContractors } from "../graphql/queries.js"
import { updateContractor } from "../graphql/mutations.js"

const ProfileContent = () => {
  const [authState, onStateChange] = useState({ state: "edit_profile" })
  const { state } = authState
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState("")

  // Cognito Variables
  const [name, setName] = useState(Auth.user.attributes.name)
  const [oldEmail, setOldEmail] = useState(Auth.user.attributes.email)
  const [newEmail, setEmail] = useState(Auth.user.attributes.email)
  const [number, setNumber] = useState(Auth.user.attributes.phone_number)
  const [oldPassword, setOldPassword] = useState(Auth.user.attributes.password)
  const [newPassword, setNewPassword] = useState(Auth.user.attributes.password)
  const [authCode, setAuthCode] = useState("")
  const [company_name, setCompanyName] = useState(
    Auth.user.attributes["custom:company_name"]
  )
  const [company_logo, setCompanyLogo] = useState(
    Auth.user.attributes["custom:company_logo"]
  )

  const editButtonClick = async () => {
    let inputs = document.getElementsByName("profile_input")
    for (let i of inputs) {
      i.disabled = false
    }
    document.getElementById("editButton").style.display = "none"
    document.getElementById("saveButton").style.display = "block"
    if (company_logo != undefined)
      document.getElementById("resetButton").style.display = "inline-block"
    document.getElementById("changePasswordButton").style.pointerEvents = "none"
  }

  const saveButtonClick = async () => {
    setError("")
    setSuccess(false)

    try {
      if (oldEmail === newEmail) {
        console.log(company_logo)
        console.log(oldEmail, "NEW: ", newEmail)
        console.log("NO NEW EMAIL")
        await Auth.updateUserAttributes(Auth.user, {
          name: name,
          phone_number: number,
          "custom:company_name": company_name,
          "custom:company_logo": company_logo || "",
        })
        const data = await API.graphql({
          query: listContractors,
        })

        await API.graphql({
          query: updateContractor,
          variables: {
            input: {
              id: data.data.listContractors.items[0].id,
              _version: data.data.listContractors.items[0]._version,
              name: company_name,
            },
          },
        })
      } else {
        console.log(oldEmail[0], "NEW: ", newEmail)
        console.log("NEW EMAIL")
        await Auth.updateUserAttributes(Auth.user, {
          name: name,
          email: newEmail,
          phone_number: number,
          "custom:company_name": company_name,
          "custom:company_logo": company_logo || "",
        })
        const data = await API.graphql({
          query: listContractors,
        })

        await API.graphql({
          query: updateContractor,
          variables: {
            input: {
              id: data.data.listContractors.items[0].id,
              _version: data.data.listContractors.items[0]._version,
              name: company_name,
            },
          },
        })
      }
      setSuccess(true)
    } catch (error) {
      console.log(error)
      setError("The email or phone number is incorrect. Please try agian.")
    }
    let inputs = document.getElementsByName("profile_input")
    for (let i of inputs) {
      i.disabled = true
    }
    document.getElementById("editButton").style.display = "block"
    document.getElementById("saveButton").style.display = "none"
    document.getElementById("resetButton").style.display = "none"
    document.getElementById("changePasswordButton").style.pointerEvents = "auto"
    if (oldEmail !== newEmail) {
      onStateChange(() => ({ ...authState, state: "verify_profile" }))
    }
  }

  const changePasswordClick = async () => {
    document.getElementById("changePasswordButton").style.display = "none"
    document.getElementById("oldPassword").style.display = "inline-block"
    document.getElementById("newPassword").style.display = "inline-block"
    document.getElementById("saveNewPassButton").style.display = "inline-block"
    document.getElementById("cancelButton").style.display = "inline-block"
  }

  const saveNewPassword = async () => {
    setError("")
    setSuccess(false)

    try {
      await Auth.changePassword(Auth.user, oldPassword, newPassword)
      console.log("Data Saved")
      setSuccess(true)
    } catch (error) {
      console.log(error)
      setError("The password could not be changed")
    }

    document.getElementById("changePasswordButton").style.display =
      "inline-block"
    document.getElementById("oldPassword").style.display = "none"
    document.getElementById("newPassword").style.display = "none"
    document.getElementById("saveNewPassButton").style.display = "none"
    document.getElementById("cancelButton").style.display = "none"
  }

  const cancelPasswordSave = async () => {
    document.getElementById("changePasswordButton").style.display =
      "inline-block"
    document.getElementById("oldPassword").style.display = "none"
    document.getElementById("newPassword").style.display = "none"
    document.getElementById("saveNewPassButton").style.display = "none"
    document.getElementById("cancelButton").style.display = "none"
  }

  const verifyEmail = async () => {
    try {
      Auth.verifyCurrentUserAttributeSubmit("email", authCode)
      onStateChange(() => ({ ...authState, state: "edit_profile" }))
      setOldEmail(newEmail)
      const data = await API.graphql({
        query: listContractors,
      })

      await API.graphql({
        query: updateContractor,
        variables: {
          input: {
            id: data.data.listContractors.items[0].id,
            _version: data.data.listContractors.items[0]._version,
            email: newEmail,
          },
        },
      })

      setSuccess(true)
    } catch (error) {
      console.log(error)
      setError("The Auth Code was incorrect.")
    }
  }

  async function changeLogo(e) {
    const file = e.target.files[0]
    try {
      const pic = await Storage.put(`${Auth.user.attributes.name}_logo`, file)
      console.log("New: ", pic)
      setCompanyLogo(pic.key)
    } catch (error) {
      console.log("Error uploading file: ", error)
    }
  }

  async function resetLogo(e) {
    try {
      let x = Auth.user.attributes["custom:company_logo"] + ".jpg"
      let y = Auth.user.attributes["custom:company_logo"] + ".png"
      await Storage.remove(x)
      await Storage.remove(y)
      console.log("LOGO RESET")
    } catch (error) {
      console.log("Error uploading file: ", error)
    }
    setCompanyLogo("")
    console.log(Auth.user.attributes["custom:company_logo"])
  }

  const checkStuff = async () => {
    console.log()
  }

  return (
    // Edit Profile
    <div className={styles.container}>
      {state === "edit_profile" && (
        <div>
          <div className={styles.header}>
            {/* Top Header With Buttons */}
            <Link to="/dashboard">
              <div className={[styles.editButton, styles.button].join(" ")}>
                <div className={styles.button_text}>Back</div>
              </div>
            </Link>
            <h1 className={styles.title_text}>Profile</h1>
            <div
              id="editButton"
              className={[styles.editButton, styles.button].join(" ")}
              onClick={editButtonClick}
            >
              <div className={styles.button_text}>Edit</div>
            </div>
            <div
              id="saveButton"
              className={[styles.saveButton, styles.button].join(" ")}
              onClick={saveButtonClick}
            >
              <div className={styles.button_text}>Save</div>
            </div>
          </div>

          {/* User Response Alerts */}
          <div className={styles.content}>
            <div style={{ margin: "12px 0px", width: "100%" }}>
              {success && (
                <Alert severity="success">
                  <strong>Success: </strong> Your data has been saved!
                </Alert>
              )}
              {error.length !== 0 && (
                <Alert severity="error">
                  <strong>Error: </strong> {error}
                </Alert>
              )}
            </div>

            {/* Company Name */}
            <div className={styles.attribute}>
              <span className={styles.attribute_text}>Company Name:</span>
              &emsp;&emsp;
              <span className={styles.a_text}>
                <input
                  name="profile_input"
                  className={styles.input_text}
                  defaultValue={Auth.user.attributes["custom:company_name"]}
                  disabled
                  onChange={event => setCompanyName(event.target.value)}
                />
              </span>
            </div>
            {/* Company Logo */}
            <div className={styles.attribute}>
              <span className={styles.attribute_text}>Company Logo:</span>
              &emsp;&emsp;&nbsp;&nbsp;
              <span className={styles.a_text}>
                <label htmlFor="companyLogo" className={styles.input_text}>
                  {Auth.user.attributes["custom:company_logo"]
                    ? Auth.user.attributes["custom:company_logo"]
                    : "Choose File"}
                </label>
                &nbsp;&nbsp;
                <div
                  id="resetButton"
                  className={styles.formButton}
                  onClick={resetLogo}
                >
                  <div className={styles.button_text}>Reset</div>
                </div>
                <input
                  id="companyLogo"
                  name="profile_input"
                  type="file"
                  accept="image/png"
                  className={styles.test}
                  onChange={changeLogo}
                  disabled
                />
              </span>
            </div>
            <hr />
            {/* Name */}
            <div className={styles.attribute}>
              <span className={styles.attribute_text}>Name:</span>
              &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
              <span className={styles.a_text}>
                <input
                  name="profile_input"
                  className={styles.input_text}
                  defaultValue={Auth.user.attributes.name}
                  disabled
                  onChange={event => setName(event.target.value)}
                />
              </span>
            </div>
            {/* Email */}
            <div className={styles.attribute}>
              <span className={styles.attribute_text}>Email:</span>
              &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
              <span className={styles.a_text}>
                <input
                  name="profile_input"
                  className={styles.input_text}
                  type="email"
                  defaultValue={Auth.user.attributes.email}
                  disabled
                  onChange={event => setEmail(event.target.value)}
                />
              </span>
            </div>
            {/* Phone Number */}
            <div className={styles.attribute}>
              <span className={styles.attribute_text}>Phone Number:</span>
              &emsp;&emsp;
              <span className={styles.a_text}>
                <input
                  name="profile_input"
                  className={styles.input_text}
                  defaultValue={Auth.user.attributes.phone_number}
                  disabled
                  onChange={event => setNumber(event.target.value)}
                />
              </span>
            </div>
            <hr />
            {/* Password */}
            <div className={styles.attribute}>
              <span className={styles.attribute_text}>Password:</span>
              &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
              <span className={styles.a_text}>
                <div
                  id="changePasswordButton"
                  className={styles.passwordButton}
                  onClick={changePasswordClick}
                >
                  <div className={styles.button_text}>Change</div>
                </div>
                {/* Password Change Inputs */}
                <div className={styles.passwordInputs}>
                  <input
                    id="oldPassword"
                    placeholder="Old Password"
                    className={styles.password}
                    type="password"
                    onChange={event => setOldPassword(event.target.value)}
                  />
                  &emsp;
                  <input
                    id="newPassword"
                    placeholder="New Password"
                    className={styles.password}
                    type="password"
                    onChange={event => setNewPassword(event.target.value)}
                  />
                </div>
                <div
                  id="saveNewPassButton"
                  className={[styles.newPasswordButton, styles.formButton].join(
                    " "
                  )}
                  onClick={saveNewPassword}
                >
                  <div className={styles.button_text}>Save</div>
                </div>
                <div
                  id="cancelButton"
                  className={[styles.cancelButton, styles.formButton].join(" ")}
                  onClick={cancelPasswordSave}
                >
                  <div className={styles.button_text}>Cancel</div>
                </div>
              </span>
            </div>
          </div>
        </div>
      )}
      {/* Verfiy Profile with Phone Number or Email is changed */}
      {state === "verify_profile" && (
        <div className={styles.verification_content}>
          <h3>A verification code has been sent to your newly changed email</h3>
          <br />
          <input
            name="verify_input"
            className={styles.input_text}
            type="number"
            onChange={event => setAuthCode(event.target.value)}
          />
          <div
            id="editButton"
            className={[styles.verifyButton, styles.button].join(" ")}
            onClick={verifyEmail}
          >
            <div className={styles.button_text}>Submit</div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ProfileContent
